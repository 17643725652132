  @import 'variables';

@mixin text-ellipsis() {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@mixin square($width) {
  width: $width;
  height: $width;
}

@mixin expand-clickable-area($spacing) {
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 100%;
    top: -$spacing;
    left: -$spacing;
    padding: $spacing;
  }
}

@mixin respond($breakpoint) {
  @if $breakpoint == phone {
    @media (max-width: 599.98px) {
      @content;
    }
  }

  @if $breakpoint == ipad {
    @media (max-width: 768px) {
      @content;
    }
  }

  @if $breakpoint == tab-port {
    @media (max-width: 960px) {
      @content;
    }
  }

  @if $breakpoint == tab-land {
    @media (max-width: 1279.98px) {
      @content;
    }
  }

  @if $breakpoint == big-desktop {
    @media (min-width: 1280px) {
      @content;
    }
  }

  @if $breakpoint == single-content {
    $content-width: calc($max-width + $slider-width * 2 + $space-11 * 2);
    @media (max-width: $content-width) {
      @content;
    }
  }

  @if $breakpoint == dual-content {
    $content-width: calc($max-width-dual + $slider-width * 2 + $space-11 * 2);
    @media (max-width: $content-width) {
      @content;
    }
  }
}

@mixin shadow($size) {
  @if $size == sm {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1);
  }

  @if $size == lg {
    box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.12),
      0px 4px 6px -2px rgba(0, 0, 0, 0.08);
  }

  @if $size == xl {
    box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.14),
      0px 10px 10px -5px rgba(0, 0, 0, 0.08);
  }

  @if $size == 2xl {
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.26);
  }
}

@mixin center-absolute-element() {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  margin: auto;
}
