@use '@angular/material' as mat;

@import '@mdi/font/css/materialdesignicons.css';
@import '@angular/cdk/overlay-prebuilt.css';
@import '~quill/dist/quill.bubble.css';
// or
@import '~quill/dist/quill.snow.css';
@include mat.core();

$dark-primary-text: rgba(black, 0.87);
$light-primary-text: white;

$primary-palette: (
  50: #feeee3,
  100: #fcd5b9,
  200: #fbba8b,
  300: #f99e5c,
  400: #f78939,
  500: #f67416,
  600: #f56c13,
  700: #f36110,
  800: #f2570c,
  900: #ef4406,
  contrast: (50: $dark-primary-text,
    100: $dark-primary-text,
    200: $dark-primary-text,
    300: $light-primary-text,
    400: $light-primary-text,
    500: $light-primary-text,
    600: $light-primary-text,
    700: $light-primary-text,
    800: $light-primary-text,
    900: $light-primary-text,
  )
);
$warn-palette: (
  50: #fee8eb,
  100: #fcc5ce,
  200: #fa9fae,
  300: #f7788d,
  400: #f65b74,
  500: #f43e5c,
  600: #f33854,
  700: #f1304a,
  800: #ef2841,
  900: #ec1b30,
  A100: #ffffff,
  A200: #ffecee,
  A400: #ffb9bf,
  A700: #ffa0a8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$my-primary: mat.define-palette($primary-palette, 500, 100, 700);
$my-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);
$my-warn: mat.define-palette($warn-palette, 500, 100, 700);

$my-theme: mat.define-light-theme(
    (
      color: (
        primary: $my-primary,
        accent: $my-accent,
        warn: $my-warn,
      ),
      typography:
      mat.define-typography-config(
        $font-family: 'Lato',
      ),
      density: 0,
    )
);

@include mat.all-component-themes($my-theme);

:root {
  --primary-light: #5eead4; // not yet
  --primary: #f47621;
  --primary-dark: #0f766e; // not yet
  --secondary-light: #bae6fd; // not yet
  --secondary: #0ea5e9; // not yet
  --secondary-dark: #0369a1; // not yet
  --blue: #586770;
  --blue-dark: #333f48;
  --white: #ffffff;
  --black: #000000;
  --text-02: #525252;
  --text-03: #a8a8a8;
  --field-01: #f4f4f4;
  --gray-200: #e5e7eb;
  --gray-900: #111827;
  --gray-500: #6b7280;
  --red-400: #f87171;
  --green-400: #4ade80;

  --brand-active: #f67416;
  --brand-background: #fff6eb;
  --brand-destructive-active: #e21d48;
  --brand-selected: #fdba72;
  --brand-success: #66a50d;
  --brand-destructive-hover: #f43e5c;

  --mode-base-00: #fff;
  --mode-base-50: #fcfcfc;
  --mode-base-100: #f4f4f5;
  --mode-base-200: #e4e4e7;
  --mode-base-400: #a1a1aa;
  --mode-base-600: #52525b;

  --mode-alpha-100: rgba(173, 173, 173, 0.2);
  --mode-alpha-200: rgba(50, 62, 72, 0.2);
  --mode-alpha-700: #323e48b2;
  --mode-alpha-900: #323e48;

  --colors-base-00: #fff;
  --colors-base-900: #323e48;

  --colors-orange-50: #fff6eb;
  --colors-orange-500: #f67416;
  --colors-orange-200: #fed6a9;

  --colors-gray-900: #202024;

  --colors-blue-gray-200: #e1e7ef;
  --colors-blue-gray-400: #94a3b8;

  --space-3: 4px;
  --space-4: 8px;
  --space-5: 10px;
  --space-6: 12px;
  --space-7: 16px;
  --space-8: 20px;
  --space-9: 24px;
  --space-11: 32px;
  --space-13: 40px;
  --space-15: 48px;
  --space-16: 56px;
  --space-17: 64px;
  --space-18: 80px;
  --space-24: 160px;

  --radius-radi-4: 8px;
  --radius-radi-6: 12px;
  --radius-radi-8: 22px;

  --md: 640px;

  --income-border: #bedbfe;
  --income-background-color: #f1f5f9;

  --expenses-border: #e4e4e7;
  --expenses-background-color: #f4f4f5;

  --liabilities-border: #fbd0e8;
  --liabilities-background-color: #fce8f4;

  --assets-border: #d9f99f;
  --assets-background-color: #ebfcca;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body {
  font-family: 'Lato', sans-serif;
  font-size: 16px;
  height: 100%;
}

ul {
  list-style-type: none;
}

mat-form-field {
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-focus-outline-width: 2px;
  }
}

mat-form-field.full-width {
  width: 100%;
}

.custom-table tr:hover {
  background-color: lightgray !important;
  cursor: pointer;
}

.has-hidden-text:hover {
  height: 70px !important;
  transition: height 0.3s ease;

  .truncate {
    white-space: normal !important;
  }
}

.text-center {
  text-align: center;
}

.option-container {
  img.Savings {
    background: var(--mode-base-50, #FCFCFC);
    border: 1px solid var(--mode-base-200, #E4E4E7);
    box-shadow: 0px 1px 2px 0px #0000000F;
    width: 36px !important;
    height: 36px !important;
    padding: 6px 0px 0px 0px;
    gap: 0px;
    border: 1px 0px 0px 0px;
    opacity: 0px;
    border-radius: 12px;
  }
}

.s-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}

.text-align-left{
  text-align: left;
}