@mixin text-base($font: regular) {
  font-family: Lato;
  font-size: 16px;
  font-style: normal;

  @if $font == 'regular' {
    font-weight: 400;
    line-height: 24px;
  }

  @if $font == 'medium' {
    font-weight: 500;
    line-height: 24px;
  }

  @if $font == 'semibold' {
    font-weight: 600;
    line-height: 28px;
  }

  @if $font == 'bold' {
    font-weight: 700;
    line-height: 24px;
  }
}

@mixin text-xs($font: 'medium') {
  font-family: Lato;
  font-size: 12px;

  @if $font == 'medium' {
    font-weight: 500;
    line-height: 16px;
  }
}

@mixin text-sm($font: 'regular') {
  font-family: Lato;
  font-size: 14px;

  @if $font == 'regular' {
    font-weight: 400;
    line-height: 28px;
  }

  @if $font == 'medium' {
    font-weight: 500;
    line-height: 22px;
  }

  @if $font == 'semibold' {
    font-weight: 600;
    line-height: 22px;
  }
}

@mixin text-lg($font: 'regular') {
  font-family: Lato;
  font-size: 18px;
  font-style: normal;

  @if $font == 'regular' {
    font-weight: 400;
    line-height: 28px;
  }

  @if $font == 'semibold' {
    font-weight: 600;
    line-height: 28px;
  }

  @if $font == 'medium' {
    font-weight: 500;
    line-height: 28px;
  }

  @if $font == 'bold' {
    font-weight: 700;
    line-height: 28px;
  }
}

@mixin text-xl($font: 'regular') {
  font-family: Gotham;
  font-size: 20px;

  @if $font == 'regular' {
    font-weight: 325;
    line-height: 32px;
  }

  @if $font == 'bold' {
    font-family: Gotham;
    font-size: 20px;
  }
}

@mixin text-2xl($font: 'regular') {
  font-family: Gotham;
  font-size: 24px;

  @if $font == 'light' {
    font-weight: 300;
    line-height: 38px;
  }
}

@mixin text-3xl($font: 'regular') {
  font-family: Gotham;
  font-size: 30px;

  @if $font == 'light' {
    font-weight: 300;
    line-height: 42px;
  }

  @if $font == 'regular' {
    font-weight: 325;
    line-height: 42px;
  }

  @if $font == 'bold' {
    font-weight: 300;
    line-height: 38px;
  }
}

@mixin text-4xl($font: 'regular') {
  font-family: Gotham;
  font-size: 36px;

  @if $font == 'light' {
    font-weight: 300;
    line-height: 48px;
  }

  @if $font == 'regular' {
    font-weight: 325;
    line-height: 48px;
  }

  @if $font == 'bold' {
    font-weight: 400;
    line-height: 48px;
  }
}

@mixin text-5xl($font: 'regular') {
  font-family: Gotham;
  font-size: 48px;

  @if $font == 'light' {
    font-weight: 300;
    line-height: 58px;
  }

  @if $font == 'regular' {
    font-weight: 300;
    line-height: 58px;
  }
}
